<template>
  <IndustryLineList></IndustryLineList>

  <!--  以前的界面不要了-->
  <!--  <div class="industryLineSetting">-->
  <!--    <p class="title">行业划分：-->
  <!--      <el-button size="mini" plain class="yes-748bfd-bg" @click="appendDialog = true">添加一级行业</el-button>-->
  <!--    </p>-->
  <!--    <el-table class="eltable" v-loading="industryLoading" ref="multipleTable" :data="tableData" tooltip-effect="dark"-->
  <!--              style="width: 100%" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}" row-key="id"-->
  <!--              :max-height="tableHeight">-->
  <!--      <el-table-column label="服务商" width="200px">-->
  <!--        <template #default="{row}">-->
  <!--          &lt;!&ndash;          <el-tag>{{row.name}} <icon-font type="icon-bianji" @click="edit(row)" class="icon"/><icon-font type="icon-copy-4-03" @click="del(row)" class="icon"/></el-tag>&ndash;&gt;-->
  <!--          <el-tag>{{ row.name }}</el-tag>-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column label="一级行业" width="500px">-->
  <!--        <template #default="{row}">-->
  <!--          <div class="flexcenter">-->
  <!--            &lt;!&ndash;          <el-tag class="m-r-10" v-for="(item,index) in row.second" :key="index">&ndash;&gt;-->
  <!--            &lt;!&ndash;            {{item.name}}<icon-font type="icon-bianji" @click="edit(item)" class="icon"/><icon-font type="icon-copy-4-03" @click="del(item)" class="icon"/>&ndash;&gt;-->
  <!--            &lt;!&ndash;          </el-tag>&ndash;&gt;-->
  <!--            <el-tag class="m-r-10" v-for="(first,index) in row.children" :key="index">-->
  <!--              {{ first.name }}-->
  <!--            </el-tag>-->
  <!--            <span class="plus" @click="append(row.id)"></span>-->
  <!--          </div>-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column label="二级行业">-->
  <!--        <template #default="{row}">-->
  <!--          <div class="flexcenter">-->
  <!--            &lt;!&ndash;          <el-tag class="m-r-10" v-for="(item,index) in row.second" :key="index">&ndash;&gt;-->
  <!--            &lt;!&ndash;            {{item.name}}<icon-font type="icon-bianji" @click="edit(item)" class="icon"/><icon-font type="icon-copy-4-03" @click="del(item)" class="icon"/>&ndash;&gt;-->
  <!--            &lt;!&ndash;          </el-tag>&ndash;&gt;-->
  <!--            <span v-for="(first,firstIndex) in row.children" :key="firstIndex">-->
  <!--               <div>{{ first.name }}</div>-->
  <!--              <el-tag class="m-r-10" v-for="(second,secondIndex) in first.children" :key="secondIndex">-->
  <!--              {{ second.name }}-->
  <!--            </el-tag>-->
  <!--            <span class="plus" @click="append(first.id)"></span>-->
  <!--            </span>-->
  <!--          </div>-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--    </el-table>-->
  <!--    <el-dialog v-model="appendDialog" v-loading="appendLoading" :before-close="handleAppendCancel"-->
  <!--               :close-on-click-modal="false" width="500px" :title="`新增${appendForm.pid?'二':'一'}级行业`">-->
  <!--      <div>-->
  <!--        <el-form :model="appendForm" ref="appendForm" :rules="appendRules">-->
  <!--          <el-form-item prop="name" :label="`${appendForm.pid?'二':'一'}级行业`">-->
  <!--            <el-input v-model.trim="appendForm.name"-->
  <!--                      :placeholder="`请输入${appendForm.pid?'二':'一'}级行业名称`"></el-input>-->
  <!--          </el-form-item>-->
  <!--        </el-form>-->
  <!--      </div>-->
  <!--      <template #footer>-->
  <!--          <span class="dialog-footer">-->
  <!--            <el-button @click="handleAppendCancel">取消</el-button>-->
  <!--            <el-button type="primary" @click="handleAppendComfirm" class="yes-748bfd-bg">添加</el-button>-->
  <!--          </span>-->
  <!--      </template>-->
  <!--    </el-dialog>-->
  <!--    <el-dialog v-model="editDialog" v-loading="editLoading" :before-close="handleEditCancel"-->
  <!--               :close-on-click-modal="false" width="500px" :title="`修改${editForm.pid?'二':'一'}级行业`">-->
  <!--      <div>-->
  <!--        <el-form :model="editForm" ref="editForm" :rules="appendRules">-->
  <!--          <el-form-item prop="name" :label="`${editForm.pid?'二':'一'}级行业`">-->
  <!--            <el-input v-model.trim="editForm.name"-->
  <!--                      :placeholder="`请输入${editForm.pid?'二':'一'}级行业名称`"></el-input>-->
  <!--          </el-form-item>-->
  <!--        </el-form>-->
  <!--      </div>-->
  <!--      <template #footer>-->
  <!--          <span class="dialog-footer">-->
  <!--            <el-button @click="handleEditCancel">取消</el-button>-->
  <!--            <el-button type="primary" @click="handleEditComfirm" class="yes-748bfd-bg">保存</el-button>-->
  <!--          </span>-->
  <!--      </template>-->
  <!--    </el-dialog>-->
  <!--    <el-dialog v-model="delDialog" v-loading="delLoading" :before-close="handleDelCancel" :close-on-click-modal="false"-->
  <!--               width="500px" title="删除二次确认">-->
  <!--      <div>-->
  <!--        确认是否删除{{ delItem.pid ? '二' : '一' }}级行业：“{{ delItem.name }}”-->
  <!--      </div>-->
  <!--      <template #footer>-->
  <!--          <span class="dialog-footer">-->
  <!--            <el-button @click="handleDelCancel">取消</el-button>-->
  <!--            <el-button type="primary" @click="handleDelComfirm" class="yes-748bfd-bg">确认</el-button>-->
  <!--          </span>-->
  <!--      </template>-->
  <!--    </el-dialog>-->
  <!--  </div>-->
</template>

<script>
import {IconFont} from "@/utils/iconfont"
import {industryList, industryAdd, industryUpdate, industryDelete} from '@/api/settings/industry'
import dictionary from "@/api/system/dictionary";
import IndustryLineList from "@/views/settings/industry-line/IndustryLineList.vue";

export default {
  name: 'industryLineSetting',
  components: {
    IndustryLineList,
    IconFont
  },
  data() {
    return {
      tableData: [],
      tableHeight: this.$store.state.tableHeight,
      appendDialog: false,                             //添加编辑,
      appendForm: {
        name: '',
        pid: ''
      },
      editDialog: false,                             //添加编辑,
      editForm: {
        name: '',
        id: '',
        pid: null
      },
      appendRules: {
        name: [{required: true, message: '请输入行业名称', trigger: ['change', 'blur']},
        ]
      },
      appendLoading: false,
      editLoading: false,
      industryLoading: false,
      delDialog: false,
      delLoading: false,
      delItem: {}
    }
  },
  mounted() {
    // this.industryList()
  },
  methods: {
    getIndustryTree(data, returnData) {
      if (!data) return returnData;
      if (Array.isArray(data)) {
        data.forEach((v, i) => {
          if (v.children.length != 0) {
            returnData?.push({
              amountId: v.amountId,//线路
              name: v.name,
              id: v.id,
              pid: v.pid,
              children: [],
            })
          } else {
            returnData?.push({
              amountId: v.amountId,
              name: v.name,
              id: v.id,
              pid: v.pid,
            })
          }

          if (v.children) {
            this.getIndustryTree(v.children, returnData?.[i]?.children)
          }
        })
      }
      return returnData;
    },
    getIndustryFromTree(tree, prop, id) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i][prop] == id) {
          return tree[i];
        }
        if (tree[i].children && tree[i].children.length > 0) {
          let find = this.getIndustryFromTree(tree[i].children, prop, id);
          if (find) {
            return find;
          }
        }
      }
      return null;
    },

    industryList() {
      // console.log('industryList')
      this.industryLoading = true;
      // industryList().then((res) => {
      //   this.industryLoading = false
      //   if (res.code == 200) {
      //     this.tableData = res.data.map(e => {
      //       return {
      //         id: e.id, name: e.name, pid: e.pid, second: e.children.map(e => {
      //           return {id: e.id, name: e.name, pid: e.pid}
      //         })
      //       }
      //     })
      //   } else {
      //     this.$message.error(res.message || res.msg);
      //   }
      // }).catch(err => {
      //   this.industryLoading = false
      // })

      dictionary.industry({}).then((res) => {
        let arr = []
        this.tableData = this.getIndustryTree(res.data, arr)
        this.industryLoading = false
      }).catch(err => {
        this.industryLoading = false
      })
    },
    append(pid) {
      this.appendForm.pid = pid;
      this.appendDialog = true;
    },
    handleAppendCancel() {
      this.appendForm.pid = '';
      this.$refs['appendForm'].resetFields();
      this.appendDialog = false;
    },
    handleAppendComfirm() {
      this.$refs['appendForm'].validate(valid => {
        if (valid) {
          this.appendLoading = true;
          industryAdd(this.appendForm).then(res => {
            this.appendLoading = false;
            if (res.code == 200) {
              this.$message.success('添加成功');
              this.handleAppendCancel();
              this.industryList();
            } else {
              this.$message.error(res.message || res.msg)
            }
          }).catch(err => {
            this.appendLoading = false;
            this.$message.error(res.message || res.msg)
          })
        }
      })
    },
    edit({id, name, pid}) {
      this.editForm.id = id;
      this.editForm.pid = pid;
      this.editForm.name = name;
      this.editDialog = true;
    },
    handleEditCancel() {
      this.editForm.id = '';
      this.editForm.pid = null;
      this.$refs['editForm'].resetFields();
      this.editDialog = false;
      return true;
    },
    handleEditComfirm() {
      this.$refs['editForm'].validate(valid => {
        if (valid) {
          this.editLoading = true;
          industryUpdate(this.editForm).then(res => {
            this.editLoading = false;
            if (res.code == 200) {
              this.$message.success('修改成功');
              this.handleEditCancel();
              this.industryList();
            } else {
              this.$message.error(res.message || res.msg)
            }
          }).catch(err => {
            this.editLoading = false;
            this.$message.error(res.message || res.msg)
          })
        }
      })
    },
    del(item) {
      this.delItem = item;
      this.delDialog = true;
    },
    handleDelCancel() {
      this.delItem = {};
      this.delDialog = false;
    },
    handleDelComfirm() {
      this.delLoading = true;
      industryDelete({id: this.delItem.id}).then(res => {
        this.delLoading = false;
        if (res.code == 200) {
          this.$message.success('删除成功');
          this.handleDelCancel();
          this.industryList();
        } else {
          this.$message.error(res.message || res.msg)
        }
      }).catch(err => {
        this.delLoading = false;
        this.$message.error(res.message || res.msg)
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.industryLineSetting {
  padding: 10px;

  .title {
    font-weight: bold;
  }

  .icon {
    font-size: 12px;
    cursor: pointer;
    margin-left: 5px;
  }

  .plus {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #637DFF;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 12px;
      width: 2px;
      height: 20px;
      background: #637DFF;
    }

    &::after {
      content: '';
      position: absolute;
      top: 15px;
      left: 3px;
      width: 20px;
      height: 2px;
      background: #637DFF;
    }
  }

  .flexcenter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.m-r-10 {
  margin-right: 10px;
}
</style>